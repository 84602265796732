import "slick-carousel";
(function ($) {
  "use strict";
  $(function () {
    $(".carousel-main").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: true,
      swipeToSlide: true,
      prevArrow:
        '<span><svg class="svg-inline--fa fa-angle-left fa-w-8 slick-prev fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg></span>',
      nextArrow:
        '<span><svg class="svg-inline--fa fa-angle-right fa-w-8 slick-next fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg></span>',
    });
    //begin of circle arc activity

    let circle = document.querySelector("circle");
    let radius = circle.r.baseVal.value;
    let circumference = radius * 2 * Math.PI;
    let percent = 0;
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;

    function setProgress() {
      const offset = circumference - (percent / 100) * circumference;
      circle.style.strokeDashoffset = offset;
      percent++;
      if (percent > 100) {
        percent = 0;
      }
    }

    //end of circle arc activity
    let $reqSlider = $(".req__slider");
    let $reqBuild = $(".req__build");
    const pause = 5000;
    const timeSpeed = 1000;
    $reqSlider.slick({
      infinite: true,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      fade: true,
      speed: timeSpeed,
      autoplayHoverPause: false,
    });

    $reqBuild.slick({
      infinite: true,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: pause,
      fade: false,
      speed: timeSpeed,
      autoplayHoverPause: false,
    });

    $(".req__build").on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        $(".req__dot_js-active").removeClass("req__dot_js-active");
        $(".req__dot").eq(nextSlide).addClass("req__dot_js-active");
        $reqSlider.slick("slickGoTo", nextSlide);
        percent = 0;
      }
    );
    $reqBuild.slick("slickNext");

    if ($reqBuild.length === 0) {
      $(".progress-ring").hide();
    } else {
      setInterval(setProgress, (pause + timeSpeed) / 100);
    }

    $(document).click(function (e) {
      let $currItem = $(e.target).closest(".req__dot");
      if ($currItem.length !== 0) {
        let currIndex = $currItem.index();
        console.log(currIndex);
        $reqBuild.slick("slickGoTo", currIndex);
      }
    });

    $(".carousel-news").slick({
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 7000,
      autoplayHoverPause: true,
    });

    $(".carousel-news-anchor").on("click", function (e) {
      e.preventDefault();
      const $this = $(this);
      const index = $this
        .closest(".carousel-news-links")
        .find(".carousel-news-anchor")
        .index($this);
      $(".carousel-news").slick("slickGoTo", index);
    });
  });
})(jQuery);
